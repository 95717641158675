import React from 'react';
import { Container } from 'react-bootstrap';
import a1mart from '../Images/A1Mart_Comingsoon.jpg';

export const Home = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <img src={a1mart} alt="A1 Mart coming soon" className="img-fluid" />
    </Container>
  );
};
